import SEO from '@/../next-seo.config';
import Footer from '@/components/ui/Footer.jsx';
import Header from '@/components/ui/Header.jsx';
import { useGAPageViews } from '@/hooks/useGAPageViews';
import { project } from '@/lib/project';
import '@/styles/globals.css';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from 'next-i18next.config';
import { DefaultSeo, NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import Script from 'next/script.js';
import { getCanonicalUrl } from 'routes.js';

const GoogleAdVignette = dynamic(
	() => import('@/components/@google/GoogleAdVignette'),
	{
		ssr: false,
	}
);
const NextNProgress = dynamic(() => import('nextjs-progressbar'), {
	ssr: false,
});

//If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ['latin'] });

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
	const router = useRouter();
	const languageAlternates = [];

	if (pageProps.languageAlternates) {
		languageAlternates.push(...pageProps.languageAlternates);
	} else {
		languageAlternates.push(
			...router.locales
				.map((locale) => {
					if (locale === 'default') {
						return null;
					}
					return {
						hrefLang: locale,
						href: getCanonicalUrl(router.pathname, locale, router.query),
					};
				})
				.filter(Boolean)
		);
	}
	const defaultVersion = languageAlternates.find(
		(l) => l.hrefLang === project.defaultLocale
	);
	if (defaultVersion) {
		languageAlternates.push({
			hrefLang: 'x-default',
			href: defaultVersion.href,
		});
	}

	useGAPageViews({
		gaMeasurementId: project.googleAnalyticsId,
	});

	return (
		<>
			<DefaultSeo
				{...SEO}
				dangerouslySetAllPagesToNoIndex={project.noIndex}
				dangerouslySetAllPagesToNoFollow={project.noIndex}
			/>
			<NextSeo languageAlternates={languageAlternates} />
			{pageProps.schema && (
				<Script
					strategy="beforeInteractive"
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(pageProps.schema),
					}}
				/>
			)}
			{pageProps.schemas &&
				pageProps.schemas.map((schema) => (
					<Script
						key={`${schema['@type']}_${schema['name']}`}
						strategy="beforeInteractive"
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(schema),
						}}
					/>
				))}

			<NextNProgress color="#2563eb" options={{ showSpinner: false }} />
			<SessionProvider session={session}>
				<div className={inter.className}>
					{!pageProps.disableHeader && (
						<Header
							{...pageProps.headerProps}
							languageAlternates={languageAlternates}
						/>
					)}
					<Component {...pageProps} />
					{!pageProps.disableFooter && <Footer />}
				</div>
			</SessionProvider>
			{/* <FacebookPixel /> */}
			<SpeedInsights />
			<GoogleAdVignette />
		</>
	);
};

export default appWithTranslation(MyApp, nextI18nextConfig);
