import { classNames } from '@/lib/utils';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { i18nRoute } from 'routes';

const userNavigation = [
	// { name: 'Your Profile', href: '#' },
	// { name: 'Favorites', href: '#' },
];

export function ProfilePicture({ user }) {
	if (!user) {
		return <UserCircleIcon aria-hidden className="h-9 w-9 text-gray-500" />;
	}
	return user.image ? (
		<Image
			width={40}
			height={40}
			className="h-8 w-8 rounded-full"
			src={user.image}
			alt={user.name}
			title={user.name}
		/>
	) : user.name ? (
		<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
			<span className="text-sm font-medium leading-none text-white">
				{user.name.slice(0, 1)}
			</span>
		</span>
	) : (
		<span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
			<svg
				className="h-full w-full text-gray-300"
				fill="currentColor"
				viewBox="0 0 24 24"
			>
				<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
			</svg>
		</span>
	);
}

export function ProfileDropdown() {
	const { t } = useTranslation(['common']);
	const { data: session } = useSession();
	const user = session?.user;
	return (
		<Menu as="div" className="relative shrink-0">
			<Menu.Button className="-m-2 flex items-center p-2">
				<span className="sr-only">Open user menu</span>
				<ProfilePicture user={user} />
			</Menu.Button>
			<Transition
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
					{user ? (
						<Menu.Item>
							{({ active }) => (
								<button
									className={classNames(
										active ? 'bg-gray-50' : '',
										'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900'
									)}
									onClick={() => signOut()}
								>
									{t('common:buttons.signOutBtn')}
								</button>
							)}
						</Menu.Item>
					) : (
						<Menu.Item>
							{({ active }) => (
								<button
									className={classNames(
										active ? 'bg-gray-50' : '',
										'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900'
									)}
									onClick={() => signIn()}
								>
									{t('common:buttons.signInBtn')}
								</button>
							)}
						</Menu.Item>
					)}
				</Menu.Items>
			</Transition>
		</Menu>
	);
}

export function ProfileSlideOver() {
	const { data: session } = useSession();
	const { t, i18n } = useTranslation(['common']);
	if (session) {
		const { user } = session;

		return (
			<>
				<div className="flex items-center">
					<div className="flex-shrink-0">
						<ProfilePicture user={user} />
					</div>
					<div className="ml-3">
						<div className="text-base font-medium text-gray-800">
							{user.name}
						</div>
						<div className="text-sm font-medium text-gray-500">
							{user.email}
						</div>
					</div>
					<button
						type="button"
						className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
					>
						<span className="sr-only">View notifications</span>
						<BellIcon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div className="mt-3 space-y-1">
					{userNavigation.map((item) => (
						<Link
							key={item.name}
							href={item.href}
							className="block rounded-md py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
						>
							{item.name}
						</Link>
					))}
					<button
						className="block rounded-md py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
						onClick={() => signOut()}
					>
						{t('common:buttons.signOutBtn')}
					</button>
				</div>
			</>
		);
	}
	return (
		<>
			<div>
				<Link
					href={i18nRoute('/auth/signup', i18n.language)}
					className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
				>
					{t('common:buttons.signUpBtn')}
				</Link>
				<p className="mt-6 text-center text-base font-medium text-gray-500">
					{t('common:labels.existingUser')}{' '}
					<button
						onClick={() => signIn()}
						className="text-indigo-600 hover:text-indigo-500"
					>
						{t('common:buttons.signInBtn')}
					</button>
				</p>
			</div>
		</>
	);
}
